import axios, {AxiosRequestConfig} from 'axios';
import produce from 'immer';
import {MethodStatus} from 'types';

const client = (() => {
  return axios.create({
    baseURL: process.env.REACT_APP_TOOLBOX_HOST,
    withCredentials: true,
  });
})();

export const request = async <T = any>(options: AxiosRequestConfig<any>) => {
  const onSuccess = (response: {data: T}) => {
    return response.data;
  };
  const onError = (error: {response: any}) => {
    const clearStorageErrors = [401, 429, 403];
    if (error.response?.status && clearStorageErrors.includes(error.response.status)) {
      localStorage.clear();
    }
    return Promise.reject(error.response);
  };
  return client(options).then(onSuccess).catch(onError);
};

export const login = (data: {email: string; password: string}) => {
  return request({
    url: '/users/login',
    method: 'POST',
    data,
  });
};

export const forgotPassword = (data: {email: string}) => {
  return request({
    url: '/users/password/reset',
    method: 'PUT',
    data,
  });
};

export const register = (data: {email: string; password: string; token: string}) => {
  return request({
    url: '/users/register',
    method: 'PUT',
    data,
  });
};

export const invite = (data: {email: string; firstName: string; lastName: string; role: string}) => {
  return request({
    url: '/users/invite',
    method: 'POST',
    data,
  });
};

export const logout = () => {
  return request({
    url: '/users/logout',
    method: 'GET',
  });
};

export const getMethods = async (statuses: MethodStatus[] = [MethodStatus.PUBLISHED]) => {
  return request<ToolboxObject.Method[]>({
    url: '/methods',
    method: 'GET',
    params: {
      statuses,
    },
  });
};

export const getMethod = async (id: number) => {
  return request<ToolboxObject.MethodDetails>({
    url: `/methods/${id}`,
    method: 'GET',
  });
};

export const createMethod = async (data: FormData) => {
  return request({
    url: '/methods',
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  });
};

export const deleteMethod = async (id: number) => {
  return request<ToolboxObject.Method[]>({
    url: `/methods/${id}`,
    method: 'DELETE',
  });
};

export const updateMethod = async (id: number, data: FormData) => {
  return request({
    url: `/methods/${id}`,
    method: 'PUT',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  });
};

export const getMethodSubcategories = async () => {
  return request<ToolboxObject.Subcategory[]>({
    url: `/categories/subcategories`,
    method: 'GET',
  });
};

export const getRecipeCategories = async () => {
  return request<ToolboxObject.WorkshopCategory[]>({
    url: `/recipes/categories`,
    method: 'GET',
  });
};

const formatWorkshop = (workshop: ToolboxObject.Workshop) => {
  workshop.sessions.sort((a: ToolboxObject.WorkshopSession, b: ToolboxObject.WorkshopSession) => {
    return a.order - b.order;
  });
  return workshop.sessions.forEach((session: ToolboxObject.WorkshopSession) => {
    session.editable_methods.sort((a: ToolboxObject.EditableMethod, b: ToolboxObject.EditableMethod) => {
      return a.order - b.order;
    });
  });
};

export const getWorkshops = async (params?: ToolboxObject.FindWorkshopParams, isRecipes?: boolean) => {
  const workshops = await request({
    url: isRecipes ? '/recipes' : '/workshops',
    method: 'GET',
    params,
  });
  if (workshops) {
    return produce(workshops, (draft: ToolboxObject.Workshop[]) => {
      draft.forEach((workshop: ToolboxObject.Workshop) => {
        return formatWorkshop(workshop);
      });
    });
  }
  return workshops;
};

export const getRecipes = async (params?: ToolboxObject.FindWorkshopParams) => {
  return getWorkshops(params, true);
};

export const getWorkshop = async (id: number, isRecipe?: boolean) => {
  const workshop = await request({
    url: `/${isRecipe ? 'recipes' : 'workshops'}/${id}`,
    method: 'GET',
  });
  if (workshop) {
    return produce(workshop, (draft: ToolboxObject.Workshop) => {
      return formatWorkshop(draft);
    });
  }
  return workshop;
};

export const getRecipe = async (id: number) => {
  return getWorkshop(id, true);
};

export const toggleLikeMethod = async (methodId: number, isLiked: boolean) => {
  return request({
    url: `/methods/${methodId}/${isLiked ? 'unfavorite' : 'favorite'}`,
    method: 'PUT',
  });
};

export const viewMethod = async (methodId: number) => {
  return request({
    url: `/methods/${methodId}/view`,
    method: 'PUT',
  });
};

export const viewWorkshop = async (workshopId: number) => {
  return request({
    url: `/workshops/${workshopId}/view`,
    method: 'PUT',
  });
};

export const addWorkshopUsers = async (workshopId: number, data: {userIds: number[]; access: ToolboxObject.WorkshopUserAccess}): Promise<void> => {
  return request({
    url: `/workshops/${workshopId}/users`,
    method: 'POST',
    data,
  });
};

export const getWorkshopAvailableUsers = async (workshopId: number): Promise<ToolboxObject.User[]> => {
  return request({
    url: `/workshops/${workshopId}/users`,
    method: 'GET',
  });
};

export const toggleLikeRecipe = async (recipeId: number, isLiked: boolean) => {
  return request({
    url: `/recipes/${recipeId}/${isLiked ? 'unfavorite' : 'favorite'}`,
    method: 'PUT',
  });
};

export const viewRecipe = async (recipeId: number) => {
  return request({
    url: `/recipes/${recipeId}/view`,
    method: 'PUT',
  });
};

export const saveWorkshop = async (
  title: string,
  sessions: ToolboxObject.WorkshopSession[],
  id: number,
  description: string,
  requirements: string[],
  benefits: string[],
  category?: ToolboxObject.WorkshopCategory | null,
  teaser?: string | null,
  checklist?: ToolboxObject.ChecklistTask[],
  isRecipe?: boolean
) => {
  return request({
    url: `/${isRecipe ? 'recipes' : 'workshops'}/${id}`,
    method: 'PUT',
    data: {title, sessions, description, requirements, benefits, categoryId: category?.id ?? null, checklist, teaser},
  });
};

export const saveRecipe = async (
  title: string,
  sessions: ToolboxObject.WorkshopSession[],
  id: number,
  description: string,
  requirements: string[],
  benefits: string[],
  category?: ToolboxObject.WorkshopCategory | null,
  teaser?: string | null,
  checklist?: ToolboxObject.ChecklistTask[]
) => {
  return saveWorkshop(title, sessions, id, description, requirements, benefits, category, teaser, checklist, true);
};

export const miroBoardFromWorkshop = async (id: number) => {
  return request({
    url: `/workshops/${id}/miro`,
    method: 'POST',
  });
};

export const linkMiroBoardToWorkshop = async (id: number, href: string | null) => {
  return request({
    url: `/workshops/${id}/miro`,
    method: 'PUT',
    data: {href},
  });
};

export const createWorkshop = async (
  title: string,
  sessions: ToolboxObject.WorkshopSession[],
  description: string,
  requirements: string[],
  benefits: string[],
  category: ToolboxObject.WorkshopCategory | null,
  teaser: string | null,
  checklist: ToolboxObject.ChecklistTask[],
  isRecipe?: boolean
) => {
  return request({
    url: isRecipe ? '/recipes' : `/workshops`,
    method: 'POST',
    data: {title, sessions, description, requirements, benefits, categoryId: category?.id ?? null, checklist, teaser},
  });
};

export const createRecipe = async (
  title: string,
  sessions: ToolboxObject.WorkshopSession[],
  description: string,
  requirements: string[],
  benefits: string[],
  category: ToolboxObject.WorkshopCategory | null,
  teaser: string | null,
  checklist: ToolboxObject.ChecklistTask[]
) => {
  return createWorkshop(title, sessions, description, requirements, benefits, category, teaser, checklist, true);
};

export const exportAsRecipe = async (workshopId: number, data: {category: ToolboxObject.WorkshopCategory | null; teaser: string | null}) => {
  return request({
    url: `/workshops/${workshopId}/create-recipe`,
    method: 'POST',
    data: {categoryId: data.category?.id ?? null, teaser: data.teaser},
  });
};

export const duplicateWorkshop = async (workshopId: number, data: {title: string}) => {
  return request({
    url: `/workshops/${workshopId}/duplicate`,
    method: 'POST',
    data,
  });
};

export const deleteWorkshop = async (workshopId: number, isRecipe?: boolean) => {
  return request({
    url: `/${isRecipe ? 'recipes' : 'workshops'}/${workshopId}`,
    method: 'DELETE',
  });
};
export const deleteRecipe = async (workshopId: number) => {
  return deleteWorkshop(workshopId, true);
};

export const uploadMethodAttachments = (formData: FormData) => {
  return request<ToolboxObject.IFile[]>({
    url: '/editable-methods/files',
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  });
};

export const deleteMethodAttachments = (fileIds: string[]) => {
  return request<ToolboxObject.IFile[]>({
    url: '/editable-methods/files',
    method: 'DELETE',
    data: {fileIds},
  });
};

export const getFileUrl = (id: string) => {
  return request({
    url: `/files/${id}`,
  });
};

export const getSelf = () => {
  return request<ToolboxObject.User>({
    url: '/users/me',
    method: 'GET',
  });
};

export const disconnectMiro = () => {
  return request({
    url: '/miro',
    method: 'DELETE',
  });
};

export const getTranslations = () => {
  return request<ToolboxObject.Translation[]>({
    url: '/translations',
    method: 'GET',
  });
};

export const getAITranslation = (data: {message: string; lang: string}) => {
  return request<string[]>({
    url: '/translations/help',
    method: 'POST',
    data,
  });
};

export const updateTranslation = (id: number, data: Omit<ToolboxObject.Translation, 'id'>) => {
  return request<ToolboxObject.Translation>({
    url: `/translations/${id}`,
    method: 'PUT',
    data,
  });
};

export const createTranslation = (data: Omit<ToolboxObject.Translation, 'id'>) => {
  return request<ToolboxObject.Translation>({
    url: `/translations`,
    method: 'POST',
    data,
  });
};

export const deleteTranslation = (id: number) => {
  return request<number>({
    url: `/translations/${id}`,
    method: 'DELETE',
  });
};

export type MethodDetailsTranslationFn<T = number> = (
  methodId: number,
  itemId: T,
  data: ToolboxObject.Translation
) => Promise<ToolboxObject.MethodTranslationDetails>;

export const upsertMethodTranslation: MethodDetailsTranslationFn<string> = (methodId, itemId, data) => {
  return request<ToolboxObject.MethodTranslation>({
    url: `/translations/methods/${methodId}`,
    method: 'POST',
    data: {
      ...data,
      method_key: itemId,
    },
  });
};

export const upsertMethodInfoTranslation: MethodDetailsTranslationFn = (methodId, itemId, data) => {
  return request({
    url: `/translations/methods/${methodId}/infos`,
    method: 'POST',
    data: {
      ...data,
      method_info_id: itemId,
    },
  });
};

export const upsertMethodMaterialTranslation: MethodDetailsTranslationFn = (methodId, itemId, data) => {
  return request<ToolboxObject.MethodMaterialTranslation>({
    url: `/translations/methods/${methodId}/materials`,
    method: 'POST',
    data: {
      ...data,
      method_material_id: itemId,
    },
  });
};

export const upsertMethodStepTranslation: MethodDetailsTranslationFn = (methodId, itemId, data) => {
  return request<ToolboxObject.MethodStepTranslation>({
    url: `/translations/methods/${methodId}/steps`,
    method: 'POST',
    data: {
      ...data,
      method_step_id: itemId,
    },
  });
};
