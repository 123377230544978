import {DraggableLocation, DropResult} from '@hello-pangea/dnd';
import {translateMethod} from 'hooks';
import produce, {Draft} from 'immer';
import {WritableDraft} from 'immer/dist/internal';
import _get from 'lodash/get';
import {draggableIdToEditableMethod} from 'pages/WorkshopsPage/helpers';

export type MoveDraggedItemProps<T = any> = {
  dropResult: DropResult;

  setValue(arr: T[]): void;
  value: T[];
  getInnerPath?: (destination: DraggableLocation) => string;
};

export const moveDraggedItem = <T>(props: MoveDraggedItemProps<T>) => {
  const {dropResult, setValue, value, getInnerPath} = props;
  const {destination, source} = dropResult;
  if (!destination || source.droppableId !== destination.droppableId) {
    return;
  }
  const innerPath = getInnerPath?.(destination);
  const newValue = produce(value, (draft) => {
    const initialDraftValue = innerPath ? _get(draft, innerPath) : draft;
    initialDraftValue.splice(source.index, 1);
    const item: Draft<T> = innerPath ? _get(value, innerPath)?.[source.index] : value?.[source.index];
    initialDraftValue.splice(destination.index, 0, item);
  });
  setValue(newValue);
};

export type HandleWorkshopMethodDragProps = {
  result: DropResult;
  activeSession: number;
  creatorDroppableId: string;
  setSessions(sessions: ToolboxObject.WorkshopSession[]): void;
  sessions: ToolboxObject.WorkshopSession[];
  methods?: ToolboxObject.Method[];
  locale: ToolboxObject.Locale;
  self?: ToolboxObject.User;
};

export const handleWorkshopMethodDrag = (props: HandleWorkshopMethodDragProps) => {
  const {result, activeSession, creatorDroppableId, sessions, setSessions, methods, locale, self} = props;
  const {destination, source, draggableId} = result;

  const deleteMethod = (draft: WritableDraft<ToolboxObject.WorkshopSession>[], sourceIndex: number) => {
    return draft[activeSession].editable_methods.splice(sourceIndex, 1)[0];
  };
  const addMethod = (draft: WritableDraft<ToolboxObject.WorkshopSession>[], destinationIndex: number, method: ToolboxObject.EditableMethod) => {
    draft[activeSession].editable_methods.splice(destinationIndex, 0, method);
  };

  const sortByOrder = (draft: WritableDraft<ToolboxObject.WorkshopSession>[]) => {
    draft[activeSession].editable_methods.forEach((item, i) => {
      item.order = i;
    });
  };

  // Workshop method has been dragged
  if (source.droppableId === creatorDroppableId) {
    // Workshop method has been dragged out
    if (!destination) {
      setSessions(
        produce(sessions, (draft) => {
          deleteMethod(draft, source.index);
        })
      );
      // Workshop method location has been changed
    } else if (destination.droppableId === creatorDroppableId) {
      setSessions(
        produce(sessions, (draft) => {
          const method = deleteMethod(draft, source.index);
          addMethod(draft, destination.index, method);
          sortByOrder(draft);
        })
      );
    }
    // Library method has been dragged to Workshop methods
  } else if (destination?.droppableId === creatorDroppableId) {
    setSessions(
      produce(sessions, (draft) => {
        const methodToAdd = draggableIdToEditableMethod(methods || [], draggableId);
        const translatedOriginalMethod = translateMethod(locale, methodToAdd.original_method);
        const translatedMethodToAdd: ToolboxObject.EditableMethod = {
          ...methodToAdd,
          original_method: translatedOriginalMethod,
          facilitator: self?.first_name || '',
        };
        addMethod(draft, destination.index, translateMethod(locale, translatedMethodToAdd));
        sortByOrder(draft);
      })
    );
  }
};

export enum DropType {
  WorkshopRequirement = 'WorkshopRequirement',
  WorkshopBenefit = 'WorkshopBenefit',
  WorkshopChecklist = 'WorkshopChecklist',
  WorkshopSteps = 'WorkshopSteps',

  MethodLibrary = 'MethodLibrary',
}
