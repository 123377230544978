import React from 'react';
import {useLocation} from 'react-router-dom';
import {WorkshopTemplate} from './WorkshopTemplate';

export const NewWorkshopPage: React.FC = () => {
  const {state} = useLocation();

  return (
    <WorkshopTemplate
      workshop={{
        sessions: state?.recipe_sessions ?? undefined,
        description: state?.recipe_description,
        benefits: state?.recipe_benefits,
        requirements: state?.recipe_requirements,
      }}
    />
  );
};
