import React, {useState} from 'react';
import classNames from 'classnames';
import {Button, LocalizedText} from '../../../components';

interface DeleteButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
}

export const DeleteButton: React.FC<DeleteButtonProps> = ({onClick, className = ''}) => {
  const [clicked, setClicked] = useState(false);
  const onFirstClick = () => {
    setClicked(true);
  };

  return clicked ? (
    <Button onClick={onClick} className={classNames(className, 'rounded-none gap-1 bg-red-100 border-deletered')}>
      <p className="text-red-600">
        <LocalizedText id="common.confirm" />?
      </p>
    </Button>
  ) : (
    <Button
      onClick={(event) => {
        event.stopPropagation();
        onFirstClick();
      }}
      className={className}
    >
      <p>
        <LocalizedText id="common.delete" />
      </p>
    </Button>
  );
};
