import React from 'react';
import {Button, LocalizedText} from 'components';
import {ThreeDots} from 'assets';
import {Popover, Transition} from '@headlessui/react';
import {flip, offset, shift, useFloating} from '@floating-ui/react-dom';
import {DeleteButton} from './DeleteButton';
import {useWorkshopActions} from 'hooks';
import {UserRole} from 'types';
import {RecipeFieldsModal} from './RecipeFieldsModal';
import {AddUserModal} from './AddUserModal';
import {toast} from 'react-toastify';
import {useIntl} from 'react-intl';
import {DuplicateModal} from './DuplicateModal';

interface WorkshopActionsProps {
  workshopId?: number;
  isRecipe?: boolean;
  isReadyOnly?: boolean;
  userRole: string;
  hasUnsavedChanges?: boolean;
  isLibrary?: boolean;
  plainButton?: boolean;
}

export const WorkshopActions: React.FC<WorkshopActionsProps> = ({plainButton, isLibrary, hasUnsavedChanges, workshopId, isReadyOnly, isRecipe, userRole}) => {
  const intl = useIntl();

  const {refs, floatingStyles} = useFloating({placement: 'bottom-end', middleware: [offset(10), flip(), shift()]});

  const [isCategoryModalOpen, setIsCategoryModalOpen] = React.useState(false);
  const [isUserModalOpen, setIsUserModalOpen] = React.useState(false);
  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = React.useState(false);

  const {handleDuplicate, handleAddWorkshopUsers, handleDelete, handleExportAsRecipe} = useWorkshopActions({workshopId, isRecipe});

  const actions = [
    {
      labelKey: 'common.duplicate',
      onClick: () => {
        if (hasUnsavedChanges) {
          toast.warn(intl.formatMessage({id: 'toast.workshop.duplicate.warning'}), {autoClose: 3000});
        } else {
          setIsDuplicateModalOpen(true);
        }
      },
      inactive: isRecipe,
    },
    {
      labelKey: 'common.share',
      onClick: () => {
        if (hasUnsavedChanges) {
          toast.warn(intl.formatMessage({id: 'toast.workshop.share.warning'}), {autoClose: 3000});
        } else {
          setIsUserModalOpen(true);
        }
      },
      inactive: isRecipe || isReadyOnly || isLibrary,
    },
    {
      labelKey: 'pages.workshop.saveAsRecipe',
      onClick: () => {
        if (hasUnsavedChanges) {
          toast.warn(intl.formatMessage({id: 'toast.workshop.createRecipe.warning'}), {autoClose: 3000});
        } else {
          setIsCategoryModalOpen(true);
        }
      },
      inactive: userRole !== UserRole.ADMIN || isRecipe || isReadyOnly || isLibrary,
    },
  ];

  const buttonClasses = '!text-black bg-white rounded-none hover:bg-gray-50';
  return workshopId ? (
    <>
      <Popover as={React.Fragment}>
        {({open, close}) => (
          <>
            <Popover.Button
              ref={refs.setReference}
              className={
                plainButton
                  ? ''
                  : 'rounded-md shadow-sm focus:outline-none focus:ring-0 bg-white hover:bg-gray-50 border border-[#CBD2D9] whitespace-nowrap my-auto w-[44px] h-[44px] flex items-center justify-center'
              }
              as="button"
            >
              <ThreeDots />
            </Popover.Button>
            <Popover.Panel>
              {open && (
                <Transition
                  as={React.Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <div
                    className="absolute flex flex-col gap-y-[1px] z-10 shadow-md p-0 w-[224px] bg-gray-50 [&>*:last-child]:rounded-b-md [&>*:first-child]:rounded-t-md rounded-md"
                    ref={refs.setFloating}
                    style={floatingStyles}
                  >
                    {actions.map(
                      (action) =>
                        !action.inactive && (
                          <Button
                            key={action.labelKey}
                            onClick={(event) => {
                              event.stopPropagation();
                              action.onClick();
                            }}
                            className={buttonClasses}
                          >
                            <p>
                              <LocalizedText id={action.labelKey} />
                            </p>
                          </Button>
                        )
                    )}
                    <DeleteButton
                      onClick={(event) => {
                        event.stopPropagation();
                        handleDelete();
                        close();
                      }}
                      className={buttonClasses}
                    />
                  </div>
                </Transition>
              )}
            </Popover.Panel>
          </>
        )}
      </Popover>
      <RecipeFieldsModal
        isOpen={isCategoryModalOpen}
        onClose={() => setIsCategoryModalOpen(false)}
        exportAsRecipe={(selectedCategory: ToolboxObject.WorkshopCategory | null, newTeaser: string | null) => {
          handleExportAsRecipe({
            category: selectedCategory,
            teaser: newTeaser,
          });
        }}
      />
      {handleAddWorkshopUsers && workshopId && (
        <AddUserModal
          workshopId={workshopId}
          isOpen={isUserModalOpen}
          onClose={() => setIsUserModalOpen(false)}
          handleAddWorkshopUsers={handleAddWorkshopUsers}
        />
      )}
      {handleDuplicate && workshopId && (
        <DuplicateModal
          isOpen={isDuplicateModalOpen}
          onClose={() => setIsDuplicateModalOpen(false)}
          handleDuplicate={(newTitle: string) => {
            handleDuplicate(newTitle);
          }}
        />
      )}
    </>
  ) : null;
};
