import React from 'react';
import {Socket} from 'socket.io-client';

type UseLiveWorkshopUpdatesProps = {
  socket?: Socket;
  enabled?: boolean;
  currentWorkshopState: ToolboxObject.WorkshopEditableState;
  forceRefresh?: boolean;
};

export const useLiveWorkshopUpdates = (props: UseLiveWorkshopUpdatesProps) => {
  const {socket, enabled = true, currentWorkshopState, forceRefresh} = props;

  React.useEffect(() => {
    if (!enabled) {
      return;
    }
    socket?.emit('state-sync', {state: currentWorkshopState, clientId: socket.id});
  }, [enabled, forceRefresh, currentWorkshopState]);
};
