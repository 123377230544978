import React, {useState} from 'react';
import produce from 'immer';
import {useOutsideEditorClick} from 'hooks';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Event from '@ckeditor/ckeditor5-utils/src/eventinfo';
import {RichTypography, TextEditor} from 'components';
import {Trash, DragStep} from '../../../assets';

interface WorkshopInfoItemProps {
  type: string;
  infos: string[];
  info: string;
  index: number;
  update: any;
  initialEditing?: boolean;
  disabled?: boolean;
}

export const WorkshopInfoItem: React.FC<WorkshopInfoItemProps> = ({type, infos, info, index, update, initialEditing, disabled}) => {
  const [editingValue, setEditingValue] = useState(info);
  const [isEditing, setIsEditing] = useState(initialEditing || false);

  const onChange = (_: Event, editor: ClassicEditor) => {
    return setEditingValue(editor.getData());
  };

  const onBlur = () => {
    setIsEditing(false);
    if (editingValue.trim() === '') {
      setEditingValue(info || `New ${type}`);
    } else {
      update(
        produce(infos, (draft) => {
          draft[index] = editingValue;
        })
      );
    }
  };
  const divRef = useOutsideEditorClick(onBlur);
  return (
    <div
      className="px-1 rounded-md flex gap-1 sm:gap-2 items-stretch text-xs sm:text-sm leading-5 font-medium text-opacity-70 w-full h-auto group/info"
      ref={divRef}
    >
      {!disabled && (
        <div
          style={{display: editingValue !== info || isEditing ? 'none' : undefined}}
          className="flex items-center justify-center w-6 h-8 bg-gray-50 sm:bg-white sm:group-hover/info:bg-gray-200 rounded-md"
        >
          <DragStep className="w-5 h-5 self-center text-gray-900 sm:group-hover/info:text-gray-900" />
        </div>
      )}
      <div className="w-full h-full group-hover/info:bg-white rounded-md outline outline-1 group-hover/info:outline-gray-100 outline-white outline-offset-[-1px] mr-7">
        {isEditing ? (
          <TextEditor data={editingValue} onChange={onChange} plugins={['Bold', 'Link', 'Italic', 'Paragraph']} />
        ) : (
          <RichTypography
            onClick={(event) => {
              if (!disabled) {
                event.stopPropagation();
                setIsEditing(true);
              }
            }}
            text={editingValue}
            className="w-full h-full resize-none px-2 py-1.5 -mb-1 min-h-[40px]"
          />
        )}
      </div>
      {!disabled && (
        <div className="flex h-full items-start justify-center invisible group-hover/info:visible pr-1 relative">
          <button
            type="button"
            className="w-8 h-8 justify-center items-center aspect-square rounded-md bg-deletered group-hover:flex hover:bg-opacity-70 transition-colors absolute right-0"
            onClick={(event) => {
              event.stopPropagation();
              return update(
                produce(infos, (draft) => {
                  draft.splice(index, 1);
                })
              );
            }}
          >
            <Trash className="w-3.5 h-3.5 mx-auto" fill="white" fillOpacity="1" />
          </button>
        </div>
      )}
    </div>
  );
};
