import React, {useState} from 'react';

interface RecipeTeaserProps {
  initialTeaser: string | null;
  updateTeaser: (text: string | null) => void;
  disabled?: boolean;
}

export const RecipeTeaser: React.FC<RecipeTeaserProps> = ({initialTeaser, updateTeaser, disabled}) => {
  const [editingValue, setEditingValue] = useState(initialTeaser);
  const onChange = (event: any) => {
    return setEditingValue(event.target.value);
  };
  const onKeyDown = (event: any) => {
    if (event.key === 'Escape') {
      setEditingValue(initialTeaser);
      event.target.blur();
    }
  };
  const onBlur = () => {
    updateTeaser(editingValue);
  };
  return (
    <div className="relative">
      <textarea
        onChange={onChange}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        onClick={(e) => {
          return e.stopPropagation();
        }}
        disabled={disabled}
        className="p-1 resize-none w-full h-full min-h-[120px] text-xs sm:text-sm leading-5 font-medium text-black rounded-md border border-black overflow-y-auto"
        value={editingValue?.slice(0, 150 - 1)}
      />
      <div className="absolute bottom-2 right-0 mr-2 mb-1 text-gray-500 text-sm">{`${editingValue?.length ?? 0}/150`}</div>
    </div>
  );
};
