import React from 'react';
import {Fire, Puzzle, Checklist} from 'assets';
import {WorkshopDescription} from './WorkshopDescription';
import {WorkshopInfoCard} from './WorkshopInfoCard';
import {WorkshopCategorySelector} from './WorkshopCategorySelector';
import {RecipeTeaser} from './RecipeTeaser';
import {LocalizedText} from 'components';

interface WorkshopOverviewProps {
  description: string;
  setDescription: (text: string) => void;
  benefits: string[];
  setBenefits: (benefits: string[]) => void;
  requirements: string[];
  setRequirements: (requirements: string[]) => void;
  checklist: Array<ToolboxObject.ChecklistTask>;
  setChecklist: React.Dispatch<React.SetStateAction<ToolboxObject.ChecklistTask[]>>;
  category: ToolboxObject.WorkshopCategory | null;
  setCategory?: (category: ToolboxObject.WorkshopCategory | null) => void | undefined;
  teaser: string | null;
  setTeaser?: (teaser: string | null) => void | undefined;

  disabled?: boolean;
}

const WorkshopOverviewComponent: React.FC<WorkshopOverviewProps> = ({
  description,
  setDescription,
  benefits,
  setBenefits,
  requirements,
  setRequirements,
  checklist,
  setChecklist,
  category,
  setCategory,
  teaser,
  setTeaser,
  disabled,
}) => {
  return (
    <div className="flex flex-col gap-5">
      {setTeaser && (
        <div className="flex flex-col gap-2">
          <h5 className="text-xl font-bold">
            <LocalizedText id="pages.workshop.teaser" />
          </h5>
          <div>
            <RecipeTeaser initialTeaser={teaser} updateTeaser={setTeaser} disabled={disabled} />
          </div>
        </div>
      )}
      <div className="flex flex-col gap-2">
        <h5 className="text-xl font-bold">
          <LocalizedText id="pages.workshop.description" />
        </h5>
        <WorkshopDescription initialValue={description} update={setDescription} disabled={disabled} />
      </div>
      <div className="flex gap-4 flex-wrap lg:flex-nowrap mt-8">
        <WorkshopInfoCard type="Requirement" infos={requirements} setInfos={setRequirements} headerIcon={<Puzzle />} disabled={disabled} />
        <WorkshopInfoCard type="Benefit" infos={benefits} setInfos={setBenefits} headerIcon={<Fire />} disabled={disabled} />
      </div>
      <div className="flex flex-col gap-2 mt-8">
        <WorkshopInfoCard type="Checklist" infos={checklist} setInfos={setChecklist} headerIcon={<Checklist />} disabled={disabled} />
      </div>
      {setCategory && (
        <div className="flex flex-col gap-2">
          <h5 className="text-xl font-bold">
            <LocalizedText id="pages.workshop.category" />
          </h5>
          <WorkshopCategorySelector category={category} setCategory={setCategory} />
        </div>
      )}
    </div>
  );
};

export const WorkshopOverview = React.memo(WorkshopOverviewComponent);
