import React, {useState} from 'react';
import {DotsHorizontal} from 'assets/icons/DotsHorizontal';
import {LocalizedText, Menu, WarningModal} from 'components';
import {DragDropContext, Draggable, Droppable} from '@hello-pangea/dnd';
import {SessionTabsResult} from '../hooks/useSessionTabs';
import {useIntl} from 'react-intl';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const SessionTab: React.FC<{
  tab: number;
  isActive: boolean;
  isDragging: boolean;
  handleClick: () => void;
  session?: ToolboxObject.WorkshopSession;
  disabled?: boolean;
}> = ({tab, isActive, isDragging, handleClick, session = undefined, disabled}) => {
  const isSummary = tab === -1;
  const title = isSummary ? 'Overview' : session?.title ?? `Day ${tab + 1}`;
  return (
    <div
      aria-hidden
      className={classNames(
        'flex gap-2 py-[6px] px-2 mb-2 rounded-md hover:bg-gray-100',
        isActive ? 'bg-gray-50 text-black' : 'text-black/[0.6] hover:text-black',
        isDragging ? 'bg-gray-100' : ''
      )}
      onClick={handleClick}
    >
      <div key={tab} className="focus:outline-none whitespace-nowrap">
        {title}
      </div>
      {!disabled && isActive && !isDragging && !isSummary && <DotsHorizontal />}
    </div>
  );
};

const RenameInput: React.FC<{
  session: ToolboxObject.WorkshopSession;
  handleRenaming?: (value: string) => void;
}> = ({session, handleRenaming}) => {
  const [editingValue, setEditingValue] = useState(session.title ?? '');
  const onChange = (event: any) => {
    return setEditingValue(event.target.value);
  };
  const onKeyDown = (event: any) => {
    if (event.key === ' ') {
      event.preventDefault();
      setEditingValue(`${editingValue} `);
    } else if (event.key === 'Enter') {
      event.preventDefault();
      event.target.blur();
    } else if (event.key === 'Escape') {
      setEditingValue(session.title ?? '');
    }
  };
  const onBlur = (event: any) => {
    if (handleRenaming && event.target.value.trim() !== '') {
      handleRenaming(event.target.value);
    }
  };
  return (
    <div className="mx-4 my-2">
      <input
        /* eslint-disable-next-line jsx-a11y/no-autofocus */
        autoFocus
        onChange={onChange}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        value={editingValue}
        className="border-2 border-gray-100 max-w-full rounded-md w-40 self-center px-2 py-1"
      />
    </div>
  );
};

const SessionTabsComponent: React.FC<
  SessionTabsResult & {
    disabled?: boolean;
  }
> = ({
  activeSession,
  sessions,
  setActiveSession,
  handleAddSession,
  handleRemoveSession,
  handleRenameSession,
  droppableId,
  onDragEnd,
  onDragStart,
  disabled,
}) => {
  const intl = useIntl();

  const [tabToRemove, setTabToRemove] = React.useState<number>();
  const isOpen = typeof tabToRemove === 'number';
  const handleClose = () => {
    setTabToRemove(undefined);
  };
  const handleConfirm = () => {
    if (isOpen) {
      handleRemoveSession(tabToRemove);
      handleClose();
    }
  };
  const nSessions = sessions?.length ?? 0;
  return (
    <>
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <div className="flex gap-4 flex-nowrap text-sm leading-5 font-medium flex-wrap border-b border-b-black/60">
          <Droppable droppableId={droppableId} direction="horizontal" type="SESSIONS">
            {(provided, {isDraggingOver}) => {
              return (
                <>
                  <div className="flex gap-2 flex-wrap" {...provided.droppableProps} ref={provided.innerRef}>
                    <div className={classNames('!cursor-pointer', activeSession === -1 ? 'border-black border-b-2' : 'border-transparent border-b-2')}>
                      <SessionTab
                        tab={-1}
                        isActive={activeSession === -1}
                        isDragging={false}
                        handleClick={() => {
                          setActiveSession(-1);
                        }}
                        disabled={disabled}
                      />
                    </div>
                    {sessions.map((session, i) => {
                      return (
                        <Draggable key={`${session.order}div`} index={i} draggableId={i.toString()} isDragDisabled={nSessions < 2 || disabled}>
                          {(dragProvided, {isDragging}) => {
                            const isActive = activeSession === i;
                            const TabElement = (
                              <SessionTab
                                session={session}
                                tab={i}
                                isActive={isActive}
                                isDragging={isDragging}
                                handleClick={() => {
                                  setActiveSession(i);
                                }}
                                disabled={disabled}
                              />
                            );
                            const renameAction = {
                              title: intl.formatMessage({id: 'common.rename'}),
                              element: (
                                <RenameInput
                                  session={session}
                                  handleRenaming={(v: string) => {
                                    handleRenameSession(i, v);
                                  }}
                                />
                              ),
                            };
                            const duplicateAction = {
                              title: intl.formatMessage({id: 'common.duplicate'}),
                              onClick: () => {
                                handleAddSession(true);
                              },
                            };
                            const deleteAction = {
                              title: intl.formatMessage({id: 'common.delete'}),
                              onClick: () => {
                                setTabToRemove(i);
                              },
                            };
                            return (
                              <div
                                ref={dragProvided.innerRef}
                                {...dragProvided.draggableProps}
                                {...dragProvided.dragHandleProps}
                                className={classNames(
                                  'flex !cursor-pointer',
                                  isActive && !isDragging ? 'border-black border-b-2' : 'border-transparent border-b-2'
                                )}
                              >
                                {!disabled && isActive && !isDragging ? (
                                  <Menu
                                    items={[renameAction, duplicateAction, ...(nSessions > 1 ? [deleteAction] : [])]}
                                    triggerElement={TabElement}
                                    className="font-normal"
                                  />
                                ) : (
                                  TabElement
                                )}
                              </div>
                            );
                          }}
                        </Draggable>
                      );
                    })}
                    {!disabled && !isDraggingOver && nSessions < 10 && (
                      <button
                        type="button"
                        className="py-[6px] px-2 mb-2 focus:outline-none text-black/[0.6] rounded-md hover:bg-gray-50 hover:text-black whitespace-nowrap"
                        onClick={() => {
                          handleAddSession();
                        }}
                      >
                        <LocalizedText id="pages.workshop.addDay" />
                      </button>
                    )}
                  </div>
                  {provided.placeholder}
                </>
              );
            }}
          </Droppable>
        </div>
      </DragDropContext>
      <WarningModal
        isOpen={isOpen}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        titleKey="modals.deleteDay.title"
        descriptionKey="modals.deleteDay.description"
        confirmButtonTitleKey="modals.deleteDay.confirmTitle"
      />
    </>
  );
};

export const SessionTabs = React.memo(SessionTabsComponent);
