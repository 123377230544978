import React from 'react';
import {useWorkshop} from 'services/query';
import {toast} from 'react-toastify';
import {useParams} from 'react-router-dom';
import {Loader} from 'components';
import {RoutePath} from 'Router';
import axios, {AxiosError} from 'axios';
import {WorkshopTemplate} from './WorkshopTemplate';
import {useLocalizedNavigation} from 'hooks';

interface WorkshopPageProps {
  isRecipe?: boolean;
}
export const WorkshopPage: React.FC<WorkshopPageProps> = ({isRecipe = false}) => {
  const navigate = useLocalizedNavigation();
  const params = useParams();
  const workshopId = Number(params.workshopId);
  const {data: workshop, isLoading, error} = useWorkshop(workshopId, isRecipe);

  if (isLoading) {
    return <Loader />;
  }
  if (!workshop && error) {
    if ((error as AxiosError).status === axios.HttpStatusCode.NotFound) {
      toast.error("Workshop doesn't exist");
    }
    navigate(isRecipe ? RoutePath.library : RoutePath.workshopLibrary);
    return null;
  }
  return <WorkshopTemplate workshop={workshop} isRecipe={isRecipe} />;
};
