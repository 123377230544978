import React, {useState} from 'react';
import {Button, LocalizedText, Modal} from 'components';

interface DuplicateModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleDuplicate: (title: string) => void;
}

export const DuplicateModal: React.FC<DuplicateModalProps> = ({isOpen, onClose, handleDuplicate}) => {
  const [title, setTitle] = useState('');

  const onChange = (event: any) => {
    return setTitle(event.target.value);
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} className="sm:w-[500px] sm:h-fit sm:mx-auto">
      <div className="h-full flex gap-6 flex-col">
        <h5 className="font-bold text-xl">
          <LocalizedText id="pages.workshop.duplicate" />
        </h5>
        <div>
          <p className="text-sm">
            <LocalizedText id="pages.workshop.newWorkshopName" />
          </p>
          <textarea
            onChange={onChange}
            onClick={(e) => {
              return e.stopPropagation();
            }}
            className="p-1 resize-none w-full h-full min-h-[120px] text-xs sm:text-sm leading-5 font-medium text-black rounded-md border border-black overflow-y-auto"
            value={title}
          />
        </div>
        <div className="h-fit flex gap-2 flex-row-reverse w-full">
          <Button
            onClick={() => {
              handleDuplicate(title);
              onClose();
            }}
          >
            <LocalizedText id="common.duplicate" />
          </Button>
          <Button
            onClick={() => {
              onClose();
            }}
            className="bg-white !text-black border !border-black hover:bg-gray-50"
          >
            <LocalizedText id="common.cancel" />
          </Button>
        </div>
      </div>
    </Modal>
  );
};
