import React from 'react';
import {Draggable} from '@hello-pangea/dnd';
import {Dayjs} from 'dayjs';
import {WarningModal} from 'components';
import {Trash, Duplicate} from '../../../assets';

interface DraggableTileProps {
  children: React.ReactNode;
  index: number;
  draggableId: string;
  start?: Dayjs | null | undefined;
  end?: Dayjs | null | undefined;
  onDelete?: () => void;
  onDuplicate?: () => void;
  focusDuration?: () => void;
  disabled?: boolean;
}

export const DraggableTile: React.FC<DraggableTileProps> = ({
  children,
  index,
  draggableId,
  start = undefined,
  end = undefined,
  onDelete = undefined,
  onDuplicate = undefined,
  focusDuration = undefined,
  disabled,
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const handleClose = () => {
    setIsOpen(false);
  };
  const openModal = () => {
    setIsOpen(true);
  };
  const handleConfirm = () => {
    if (isOpen) {
      if (onDelete) {
        onDelete();
      }
      handleClose();
    }
  };
  return (
    <>
      <Draggable draggableId={draggableId} index={index} isDragDisabled={disabled}>
        {(provided) => {
          return (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
              <div className="flex flex-col sm:flex-row gap-2 sm:gap-6 group">
                {start && end && (
                  <p
                    onClick={() => {
                      if (focusDuration) {
                        focusDuration();
                      }
                    }}
                    aria-hidden
                    className="flex-shrink-0 text-xs leading-5 font-medium text-black/[0.6] rounded-md border hover:border-gray-200 border-transparent h-fit cursor-pointer p-1 -m-1"
                  >
                    {`${start.format('HH:mm')} - `}
                    <br className="hidden sm:flex" />
                    {`${end.format('HH:mm')}`}
                  </p>
                )}
                <div className="flex flex-col sm:flex-end sm:flex-row w-full gap-2 sm:gap-4">
                  {children}
                  {!disabled && onDelete && onDuplicate && (
                    <div className="flex flex-row sm:flex-col w-fit h-fit bg-gray-100 p-2 rounded-md invisible group-hover:visible gap-2">
                      <button
                        type="button"
                        className="hidden w-8 h-8 justify-center items-center aspect-square rounded-md bg-deletered group-hover:flex hover:bg-opacity-70 transition-colors"
                        onClick={(event) => {
                          event.stopPropagation();
                          openModal();
                        }}
                      >
                        <Trash className="w-3.5 h-3.5" fill="white" fillOpacity="1" />
                      </button>
                      <button
                        type="button"
                        className="flex w-8 h-8 justify-center items-center aspect-square rounded-md ring-1 bg-white ring-inset ring-gray-200 hover:bg-gray-200 transition-colors"
                        onClick={(event) => {
                          event.stopPropagation();
                          onDuplicate();
                        }}
                      >
                        <Duplicate className="w-5 h-5" fill="#34495E" fillOpacity="1" />
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        }}
      </Draggable>
      <WarningModal
        isOpen={isOpen}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        titleKey="modals.deleteMethod.title"
        confirmButtonTitleKey="modals.deleteMethod.confirmTitle"
        descriptionKey="modals.deleteMethod.description"
      />
    </>
  );
};
