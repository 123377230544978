import {CheckedCheckbox} from 'assets';
import React from 'react';

type CheckboxProps = {
  value: boolean;
  onChange: () => void;
  disabled?: boolean;
};

export const Checkbox: React.FC<CheckboxProps> = ({value, onChange, disabled}) => {
  return (
    <div
      className="h-[20px] w-[20px] cursor-pointer"
      onClick={(event) => {
        if (!disabled) {
          event.stopPropagation();
          onChange();
        }
      }}
      role="presentation"
    >
      {value ? <CheckedCheckbox /> : <div className="h-[20px] w-[20px] border rounded border-gray-300 bg-white" />}
    </div>
  );
};
