import React from 'react';
import {useIntl} from 'react-intl';
import {toast} from 'react-toastify';
import {RoutePath} from 'Router';
import {useAddWorkshopUsers, useCreateWorkshop, useDeleteWorkshop, useDuplicateWorkshop, useExportAsRecipe, useSaveWorkshop} from 'services/query';
import {useLocalizedNavigation} from './useLocalizedNavigation';

type UseWorkshopActionsProps = {
  workshopId?: number;
  isRecipe?: boolean;
};

export const useWorkshopActions = (props: UseWorkshopActionsProps = {}) => {
  const navigate = useLocalizedNavigation();
  const intl = useIntl();
  const {workshopId, isRecipe} = props;

  const {mutate: deleteWorkshop, isLoading: isDeleteLoading} = useDeleteWorkshop(isRecipe);
  const handleDelete = React.useCallback(() => {
    if (!workshopId) return;

    deleteWorkshop(
      {id: workshopId},
      {
        onSuccess: () => {
          toast.success(`${isRecipe ? 'Recipe' : 'Workshop'}  deleted successfully`);
          return navigate(isRecipe ? RoutePath.library : RoutePath.workshopLibrary);
        },
        onError: () => {
          return toast.error(`Something went wrong, ${isRecipe ? 'Recipe' : 'Workshop'} could not be deleted`);
        },
      }
    );
  }, [workshopId, deleteWorkshop, navigate]);

  const {mutate: createRecipe, isLoading: isCreateRecipeLoading} = useExportAsRecipe(Number(workshopId));
  const handleExportAsRecipe = React.useCallback(
    (data: {category: ToolboxObject.WorkshopCategory | null; teaser: string | null}) => {
      if (!workshopId) return;

      createRecipe(data, {
        onSuccess: () => {
          navigate('/library');
          return toast.success('Exported workshop as recipe successfully');
        },
        onError: () => toast.error(`Something went wrong, recipe could not be saved`),
      });
    },
    [createRecipe]
  );

  const {mutate: addWorkshopUsers, isLoading: isAddWorkshopUserLoading} = useAddWorkshopUsers(Number(workshopId));
  const handleAddWorkshopUsers = (data: {userIds: number[]; access: ToolboxObject.WorkshopUserAccess}) => {
    if (!workshopId) return;

    addWorkshopUsers(data, {
      onSuccess: () => toast.success(intl.formatMessage({id: 'toast.workshop.share.success'})),
      onError: () => toast.error(intl.formatMessage({id: 'toast.workshop.share.fail'})),
    });
  };

  const {mutate: duplicateWorkshop, isLoading: isDuplicateLoading} = useDuplicateWorkshop(Number(workshopId));
  const handleDuplicate = (title: string) => {
    if (!workshopId) return;

    duplicateWorkshop(
      {title},
      {
        onSuccess: (savedWorkshop) => {
          toast.success(intl.formatMessage({id: 'toast.workshop.duplicate.success'}));
          return navigate(`/editor/${savedWorkshop.id}`);
        },
        onError: () => toast.error(intl.formatMessage({id: 'toast.workshop.duplicate.fail'})),
      }
    );
  };

  return {
    handleDelete,
    handleExportAsRecipe,
    handleAddWorkshopUsers,
    handleDuplicate,
    isLoading: isCreateRecipeLoading || isDeleteLoading || isAddWorkshopUserLoading || isDuplicateLoading,
  };
};

export const useSaveWorkshopActions = (props: UseWorkshopActionsProps = {}) => {
  const navigate = useLocalizedNavigation();
  const intl = useIntl();
  const {workshopId, isRecipe} = props;

  const {mutate: save, isLoading: isSaveLoading} = useSaveWorkshop(isRecipe);
  const handleSave = React.useCallback(
    (data: ToolboxObject.WorkshopCreateData) => {
      if (!workshopId) return;

      const saveData = {
        id: workshopId,
        ...data,
        category: isRecipe ? data.category : null,
        teaser: isRecipe ? data.teaser : null,
      };
      save(saveData, {
        onSuccess: () => toast.success(intl.formatMessage({id: isRecipe ? 'toast.recipe.update.success' : 'toast.workshop.update.success'})),
        onError: () => toast.error(intl.formatMessage({id: isRecipe ? 'toast.recipe.update.fail' : 'toast.workshop.update.fail'})),
      });
    },
    [workshopId, save]
  );

  const {mutate: create, isLoading: isCreateLoading} = useCreateWorkshop();
  const handleCreate = React.useCallback(
    (data: ToolboxObject.WorkshopCreateData) => {
      create(data, {
        onSuccess: (savedWorkshop) => {
          toast.success(intl.formatMessage({id: 'toast.workshop.create.success'}));
          return navigate(`/editor/${savedWorkshop.id}`);
        },
        onError: () => toast.error(intl.formatMessage({id: 'toast.workshop.create.fail'})),
      });
    },
    [create, navigate]
  );

  return {
    handleCreate,
    handleSave,
    isLoading: isCreateLoading || isSaveLoading,
  };
};
