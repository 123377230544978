import React, {useState} from 'react';
import produce from 'immer';
import {TextEditor} from 'components/TextEditor';
import {RichTypography} from 'components/RichTypography';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Event from '@ckeditor/ckeditor5-utils/src/eventinfo';
import {useOutsideEditorClick} from 'hooks';

interface EditableMethodNotesProps {
  method: ToolboxObject.EditableMethod;
  update: any;
  notes?: string;
  disabled?: boolean;
}

export const EditableMethodNotes: React.FC<EditableMethodNotesProps> = ({method, notes = '', update, disabled}) => {
  const defaultNotes = notes ?? '';
  const [editingValue, setEditingValue] = useState(defaultNotes);
  const [isEditing, setIsEditing] = useState(false);

  const onChange = (_: Event, editor: ClassicEditor) => {
    return setEditingValue(editor.getData());
  };
  const onBlur = () => {
    setIsEditing(false);
    if (editingValue.trim() === '') {
      setEditingValue(defaultNotes);
    } else {
      update(
        produce(method, (draft) => {
          draft.notes = editingValue;
        })
      );
    }
    setIsEditing(false);
  };
  const divRef = useOutsideEditorClick(onBlur);
  return (
    <div
      className="w-full h-full min-h-[40px] px-3 py-2 rounded-md border border-gray-200"
      ref={divRef}
      onClick={(event) => {
        if (!disabled) {
          event.stopPropagation();
          setIsEditing(true);
        }
      }}
      role="button"
      tabIndex={0}
    >
      {isEditing ? <TextEditor data={editingValue} onChange={onChange} /> : <RichTypography className="w-full text-black/70" text={editingValue} />}
    </div>
  );
};
