import React from 'react';

interface InlineEditProps {
  className?: string | undefined;
  value: string;
  setValue: (str: string) => void;
  disabled?: boolean;
}

export const InlineEdit: React.FC<InlineEditProps> = ({className = undefined, value, setValue, disabled}) => {
  const onChange = (event: any) => {
    return setValue(event.target.value);
  };
  const onKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.target.blur();
    } else if (event.key === 'Escape') {
      setValue(value);
      event.target.blur();
    }
  };
  const onBlur = (event: any) => {
    if (event.target.value.trim() === '') {
      setValue(value);
    } else {
      setValue(event.target.value);
    }
  };
  return (
    <input
      type="text"
      aria-label="Field name"
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      disabled={disabled}
      className={`h-fit cursor-pointer transition-all rounded-md focus:bg-transparent pl-1 border border-white hover:border-gray-100 hover:pl-2 focus:pl-2 ${className}`}
    />
  );
};
