import React, {useEffect, useRef, useState} from 'react';
import {Form, Modal, UploadFile} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import Upload, {RcFile, UploadProps} from 'antd/es/upload';
import {NamePath} from 'antd/es/form/interface';
import {DragDropContext, Draggable, Droppable, DropResult} from '@hello-pangea/dnd';
import produce from 'immer';

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const getFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

interface UploaderProps extends Omit<UploadProps, 'name'> {
  name: NamePath;
  title: string;
  setFileList?(value: UploadFile[]): void;
  fileList?: UploadFile[];
}

const DraggableFileItem: React.FC<{originNode: React.ReactElement; file: UploadFile; index: number}> = ({originNode, file, index}) => {
  return (
    <Draggable draggableId={file.uid} index={index}>
      {(provided) => {
        return (
          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            {originNode}
          </div>
        );
      }}
    </Draggable>
  );
};

export const Uploader: React.FC<UploaderProps> = ({title, name, accept, multiple = false, maxCount = 5, setFileList, fileList}) => {
  const uploadRef = useRef<{fileList: UploadFile[]}>();
  const [hasChanges, setHasChanges] = useState(false);
  useEffect(() => {
    if (hasChanges) {
      setHasChanges(false);
    }
  }, [hasChanges]);

  const [files, setFiles] = useState<UploadFile[]>([]);
  useEffect(() => {
    if (fileList) {
      setFiles(fileList);
    }
  }, [fileList]);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
  };

  const handleCancel = () => setPreviewOpen(false);
  const showUploadButton = !uploadRef.current || uploadRef.current.fileList.length < maxCount;
  const droppableId = `uploader_${title}`;

  const onDragEnd = (result: DropResult) => {
    const {destination, source} = result;

    if (destination && files && source.droppableId === droppableId) {
      const fileList = produce(files, (draft) => {
        draft?.splice(source.index, 1);
        draft?.splice(destination.index, 0, files[source.index]);
      });
      setFiles(fileList);
      setFileList?.(fileList);
    }
  };

  return (
    <>
      {maxCount > 1 && setFileList ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Form.Item label={title} name={name} valuePropName="fileList" getValueFromEvent={getFile}>
            <Droppable droppableId={droppableId} type="TILE" direction="horizontal">
              {(provided, {draggingFromThisWith}) => {
                return (
                  <div className="flex" {...provided.droppableProps} ref={provided.innerRef}>
                    <Upload
                      beforeUpload={() => false}
                      listType="picture-card"
                      multiple={multiple}
                      maxCount={maxCount}
                      onPreview={handlePreview}
                      onChange={({fileList}) => {
                        setHasChanges(true);
                        setFileList(fileList);
                        setFiles(fileList);
                      }}
                      accept={accept}
                      fileList={files}
                      ref={uploadRef}
                      itemRender={(originNode, file) => (
                        <DraggableFileItem originNode={originNode} file={file} index={files.findIndex((f) => f.uid === file.uid)} />
                      )}
                    >
                      {showUploadButton && !draggingFromThisWith && (
                        <div>
                          <PlusOutlined />
                          <div style={{marginTop: 8}}>Upload</div>
                        </div>
                      )}
                    </Upload>
                    {provided.placeholder}
                  </div>
                );
              }}
            </Droppable>
          </Form.Item>
        </DragDropContext>
      ) : (
        <Form.Item label={title} name={name} valuePropName="fileList" getValueFromEvent={getFile}>
          <Upload
            beforeUpload={() => false}
            listType="picture-card"
            multiple={multiple}
            maxCount={maxCount}
            onPreview={handlePreview}
            onChange={() => setHasChanges(true)}
            accept={accept}
            ref={uploadRef}
          >
            {showUploadButton && (
              <div>
                <PlusOutlined />
                <div style={{marginTop: 8}}>Upload</div>
              </div>
            )}
          </Upload>
        </Form.Item>
      )}
      <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img alt="example" style={{width: '100%'}} src={previewImage} />
      </Modal>
    </>
  );
};
