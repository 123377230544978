import React, {useMemo, useState} from 'react';
import produce from 'immer';
import {TextEditor} from 'components/TextEditor';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Event from '@ckeditor/ckeditor5-utils/src/eventinfo';
import {RichTypography} from 'components/RichTypography';
import {useOutsideEditorClick} from 'hooks';

interface EditableMethodTitleProps {
  method: ToolboxObject.EditableMethod;
  title: string;
  update: any;
  disabled?: boolean;
}

export const EditableMethodTitle: React.FC<EditableMethodTitleProps> = ({method, title, update, disabled}) => {
  const [editingValue, setEditingValue] = useState(title);
  const [isEditing, setIsEditing] = useState(false);

  const showOriginalTitle = useMemo(() => {
    return method.original_method.title.toLowerCase().trim() !== editingValue.toLowerCase().trim();
  }, [method.original_method.title, editingValue]);

  const onChange = (_: Event, editor: ClassicEditor) => {
    return setEditingValue(editor.getData());
  };

  const onBlur = () => {
    if (editingValue.trim() === '') {
      setEditingValue(title);
    } else {
      update(
        produce(method, (draft) => {
          draft.title = editingValue;
        })
      );
    }
    setIsEditing(false);
  };

  const divRef = useOutsideEditorClick(onBlur);
  return (
    <div className="w-full h-full flex flex-wrap gap-0.5 mr-2 flex-col" ref={divRef}>
      {isEditing ? (
        <TextEditor data={editingValue} onChange={onChange} />
      ) : (
        <RichTypography
          key={title}
          onClick={(event) => {
            if (!disabled) {
              event.stopPropagation();
              setIsEditing(true);
            }
          }}
          text={editingValue}
          className="px-3 py-1 text-base leading-5 font-bold text-black break-words cursor-pointer w-fit rounded-md border hover:border-gray-100 border-white"
        />
      )}
      {showOriginalTitle && !isEditing && (
        <RichTypography
          text={`– ${method.original_method.title}`}
          className="text-gray-500 text-[10px] sm:text-[12px] leading-3 font-medium text-gray-500 pt-0.5"
        />
      )}
    </div>
  );
};
