import React, {useMemo} from 'react';
import produce from 'immer';
import {Draggable, Droppable} from '@hello-pangea/dnd';
import {MethodDetailContainer} from 'components/MethodDetail/MethodDetailContainer';

import {v4 as uuidv4} from 'uuid';
import {Modal} from '../../Modal';
import {ExpandArrow} from '../../../assets';
import {EditableMethodStep} from './EditableMethodStep';
import {EditableMethodFooter} from './EditableMethodFooter';
import {useModal} from '../../../hooks';
import {EditableMethodTitle} from './EditableMethodTitle';
import {EditableMethodDescription} from './EditableMethodDescription';
import {EditableMethodNotes} from './EditableMethodNotes';
import {EditableMethodFacilitator} from './EditableMethodFacilitator';
import {EditableMethodAttachments} from './EditableMethodAttachments';

interface EditableMethodLibraryTileProps {
  method: ToolboxObject.EditableMethod;
  update: any;
  tileIndex: number;
  durationRef: React.MutableRefObject<HTMLDivElement | undefined> | undefined;
  nested?: boolean;
  disabled?: boolean;
  workshopId?: number;
}

export const EditableMethodLibraryTile: React.FC<EditableMethodLibraryTileProps> = ({
  method,
  nested = false,
  update,
  durationRef,
  tileIndex,
  disabled,
  workshopId,
}) => {
  const {isOpen, close, open} = useModal('method', method.original_method.id.toString(), true);
  const indexedSteps = useMemo(() => {
    if (!method.steps) {
      return [];
    }
    return method.steps.map((step) => {
      return {key: uuidv4(), value: step};
    });
  }, [method.steps]);
  const onCard = () => {
    if (method.is_break) {
      return;
    }
    open();
  };
  return (
    <>
      <Modal onClose={close} isOpen={isOpen} nested={nested}>
        <MethodDetailContainer id={method.original_method.id} />
      </Modal>
      <div className="group w-full">
        <div
          className="flex rounded-xl border-solid border border-black shadow-hard bg-white
          group-hover:shadow-pop group-hover:mt-[-2px] group-hover:mb-[2px]
          transition-all font-['Inter'] w-full h-full break-words overflow-visible"
        >
          {method.original_method.iconLink && (
            <div style={{backgroundColor: method.subcategory?.category.color}} className="flex-none rounded-l-xl cursor-pointer" onClick={onCard} aria-hidden>
              <img src={method.original_method.iconLink} alt={method.original_method.icon} width={65} />
            </div>
          )}
          <div className="flex flex-col rounded-r-xl gap-x-3 gap-y-8 p-6 w-full justify-between">
            <div className="flex flex-col">
              <div className="flex justify-between pb-2">
                <EditableMethodTitle method={method} title={method.title} update={update} disabled={disabled} />
                <button
                  type="button"
                  className="focus:outline-none w-6 aspect-square hover:bg-gray-200 flex justify-center items-center rounded-md"
                  onClick={(event) => {
                    event.stopPropagation();
                    return update(
                      produce(method, (draft) => {
                        // eslint-disable-next-line no-param-reassign
                        draft.expanded = !method.expanded;
                      })
                    );
                  }}
                >
                  <ExpandArrow className={method.expanded ? 'rotate-270' : 'rotate-90'} />
                </button>
              </div>
              <EditableMethodFacilitator method={method} facilitator={method.facilitator} update={update} disabled={disabled} />
              <EditableMethodDescription method={method} description={method.teaser ?? null} update={update} disabled={disabled} />
            </div>
            {method.expanded && (
              <>
                <div className="flex flex-col gap-y-4">
                  <div className="px-3 py-1 rounded-md bg-gray-100">
                    <span className="text-sm text-gray-900 font-medium">Steps</span>
                  </div>
                  <div className="flex flex-col">
                    <Droppable droppableId={`step-list-${tileIndex}`} type={`STEPS-${tileIndex}`}>
                      {(provided) => {
                        return (
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          <div ref={provided.innerRef} {...provided.droppableProps} style={{overflow: 'hidden'}} className="pl-3">
                            {indexedSteps.map((step, index) => {
                              return (
                                <Draggable draggableId={step.key} index={index} key={step.key} isDragDisabled={disabled}>
                                  {(providedDraggable) => {
                                    return (
                                      <div
                                        ref={providedDraggable.innerRef}
                                        /* eslint-disable-next-line react/jsx-props-no-spreading */
                                        {...providedDraggable.draggableProps}
                                        /* eslint-disable-next-line react/jsx-props-no-spreading */
                                        {...providedDraggable.dragHandleProps}
                                        style={{...providedDraggable.draggableProps.style, position: 'static'}}
                                      >
                                        <EditableMethodStep method={method} step={step.value} index={index} update={update} disabled={disabled} />
                                      </div>
                                    );
                                  }}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                          </div>
                        );
                      }}
                    </Droppable>
                    {!disabled && (
                      /* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
                      <div
                        onClick={(event) => {
                          event.stopPropagation();
                          return update(
                            produce(method, (draft) => {
                              // eslint-disable-next-line no-param-reassign
                              draft.steps.push('New Step');
                            })
                          );
                        }}
                        className="rounded-md py-1 px-3 ml-10 hover:border-gray-100 hover:bg-gray-50 hover:text-black"
                      >
                        <p className="cursor-pointer box-border text-xs sm:text-sm leading-5 font-medium w-auto text-gray-500">+ Add Step</p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-col gap-y-4">
                  <div className="px-3 py-1 rounded-md bg-gray-100">
                    <span className="text-sm text-gray-900 font-medium">Notes</span>
                  </div>
                  <EditableMethodNotes method={method} notes={method.notes} update={update} disabled={disabled} />
                </div>
                <div className="flex flex-col gap-y-4">
                  <div className="px-3 py-1 rounded-md bg-gray-100">
                    <span className="text-sm text-gray-900 font-medium">Attached files</span>
                  </div>
                  <EditableMethodAttachments method={method} attachments={method.attachments} update={update} disabled={disabled} workshopId={workshopId} />
                </div>
              </>
            )}
            <EditableMethodFooter method={method} update={update} durationRef={durationRef} />
          </div>
        </div>
      </div>
    </>
  );
};
