import React from 'react';

interface ButtonProps {
  className?: string;
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export const Button: React.FC<ButtonProps> = ({children, className = '', onClick = undefined, disabled = false}) => {
  return (
    <button
      className={classNames(
        'inline-flex items-center rounded-md border border-transparent bg-black px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm enabled:hover:bg-opacity-70 disabled:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2 gap-2',
        className
      )}
      type="button"
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
